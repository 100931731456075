import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { catchError, finalize, map } from 'rxjs/operators';
import { MessageService } from './message.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { GetEmailsNFConsumidorResponse } from '../model/GetEmailsNFConsumidorResponse';
import { Observable } from 'rxjs';

@Injectable()
export class NfConsumidorService extends BaseService {

  constructor(private http: HttpClient, private message: MessageService) { super(); }

  postForm(form: any) {
    if (form.id as Number) {
      return this.atualizarNfce(form);
    } else {
      return this.salvarNfce(form);
    }
  }

  private salvarNfce(form) {
    return this.http
      .post(this.UrlService + 'nfConsumidor/salvar', form)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError))
  }

  private atualizarNfce(form) {
    return this.http
      .put(this.UrlService + 'nfConsumidor/atualizar', form)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError))
  }

  getCadastroNfceById(id: number, clone: boolean = false) {
    return this.http
      .get(`${this.UrlService}nfConsumidor/${clone ? 'clone' : 'emissao'}/${id}`)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError))
  }

  enviarNfce(nfce: number, pedidoId: number = undefined, imprimirAutomatico: boolean = undefined) {
    return this.http
      .post(this.UrlService + 'nfConsumidor/enviar', { nfce, pedidoId, imprimirAutomatico })
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError))
  }

  consultarNfce(nfceId: number) {
    return this.http
      .post(this.UrlService + 'nfConsumidor/consultar', nfceId)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  imprimirNfce(id: number) {
    return this.http
      .post(this.UrlService + `nfConsumidor/imprimir/${id}`, null, { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob))
  }

  getWithFilters(consulta: any) {
    return this.http
      .post(this.UrlService + 'nfconsumidor/buscar', consulta)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError))
  }

  cancelarNfce(nfceId: number, motivo: string) {
    let nfce: Object = {
      "Nfce": nfceId,
      "Motivo": motivo
    }

    return this.http.post(this.UrlService + 'nfconsumidor/cancelar', nfce)
      .pipe(map((res: any) => res.data));
  }

  inutilizarNfce(nfceId: number, motivo: string) {
    let nfce: Object = {
      "Nfce": nfceId,
      "Motivo": motivo
    }

    return this.http.post(this.UrlService + 'nfconsumidor/inutilizar', nfce)
      .pipe(map((res: any) => res.data));
  }

  enviarEmail(nfceId: number, email: string[]) {
    let nfce: Object = {
      "Nfce": nfceId,
      "Email": email
    }

    return this.http.post(this.UrlService + 'nfconsumidor/email', nfce)
      .pipe(map((res: any) => res.data));
  }

  getClientesPaginadoNfce(pagina: number, search: string) {
    return this.http
      .get(this.UrlService + `nfconsumidor/clientes/paginado?pageIndex=` + pagina + "&search=" + encodeURIComponent(search))
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getClienteNfceById(id: number) {
    return this.http
      .get(this.UrlService + "nfconsumidor/cliente/" + id)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getExigeInformacoesFiscais() {
    return this.http
      .get(this.UrlService + "nfconsumidor/getExigeInformacoesFiscais")
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  vendasPorProdutoNfce(consulta: any) {
    let idToast = this.message.addToast({
      title: 'Emitindo Relatório',
      msg: 'Vendas por Produto NFC-e',
      showClose: false, timeout: 20000, theme: 'bootstrap', closeOther: true
    });

    return this.http
      .post(this.UrlService + 'nfconsumidor/vendasPorProdutoNFCe', consulta, { responseType: 'blob' })
      .pipe(finalize(() => this.message.clear(idToast)))
      .pipe(catchError(super.parseErrorBlob));
  }

  private impressaoNfc = new BehaviorSubject<Object>(null);
  solicitacaoImpressao = this.impressaoNfc.asObservable();

  organizarImpressao(id?, param?) {
    if (id != null) {
      this.impressaoNfc.next({ id: id });
    } else {
      this.impressaoNfc.next(null);
    }
  }

  getEmailsNFConsumidor(nfceId: number): Observable<GetEmailsNFConsumidorResponse> {
    return this.http.get(this.UrlService + `nfconsumidor/GetEmailsNFConsumidor/${nfceId}`)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  salvarPedidoFaturamentoNFCe(vm: { pedidoId: number }): Observable<{ nfceId: number, itensValidados: { itemId: number, sucesso: boolean, motivo: string }[], sucesso: boolean, motivo: string }> {
    return this.http
      .post(this.UrlService + 'nfconsumidor/salvarNFCePedido', vm)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }
}
