import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagInputModule } from '@_mehrad/ngx-chips';

import { CentroCustoComponent } from './cadastros/centro-custo/centro-custo.component';
import { ContaCorrenteComponent } from './cadastros/conta-corrente/conta-corrente.component';
import { GrupoDespesaComponent } from './cadastros/grupo-despesa/grupo-despesa.component';
import { PortadorComponent } from './cadastros/portador/portador.component';
import { PedidoComponent } from './consultas/pedido/pedido.component';
import { TipoDespesaComponent } from './cadastros/tipo-despesa/tipo-despesa.component';
import { SharedModule } from '../shared/shared.module';
import { CentrosCustosService } from '../services/centros-custos.service';
import { ContascorrentesService } from '../services/contascorrentes.service';
import { BancosService } from '../services/bancos.service';
import { GrupoDespesasService } from '../services/grupo-despesas.service';
import { PortadorService } from '../services/portador.service';
import { TipoDespesaService } from '../services/tipo-despesa.service';
import { AlmoxarifadoComponent } from './cadastros/almoxarifado/almoxarifado.component';
import { AlmoxarifadosService } from '../services/almoxarifados.service';
import { LinhaProdutoComponent } from './cadastros/linha-produto/linha-produto.component';
import { LinhasProdutosService } from '../services/linhas-produtos.service';
import { FornecedorComponent } from './cadastros/fornecedor/fornecedor.component';
import { FornecedoresService } from '../services/fornecedores.service';
import { EstadosService } from '../services/estados.service';
import { CidadesService } from '../services/cidades.service';
import { CertificadoDigitalComponent } from './cadastros/certificado-digital/certificado-digital.component';
import { UnidadesEmpresaService } from '../services/unidades-empresa.service';
import { EditarUsuarioComponent } from './cadastros/editar-usuario/editar-usuario.component';
import { MessageService } from '../services/message.service';
import { EstornoContasReceberComponent } from './cadastros/estorno-contas-receber/estorno-contas-receber.component';
import { TitulosCobrancaService } from '../services/titulos-cobranca.service';
import { AcessosUsuarioComponent } from './consultas/acessos-usuario/acessos-usuario.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ReplicaParcelasCobrancaComponent } from './cadastros/replica-parcelas-cobranca/replica-parcelas-cobranca.component';
import { RepresentanteComponent } from './cadastros/representante/representante.component';
import { RepresentantesService } from '../services/representantes.service';
import { ClienteComponent } from './cadastros/cliente/cliente.component';
import { ClientesService } from '../services/clientes.service';
import { ReplicaParcelasFornecedorComponent } from './cadastros/replica-parcelas-fornecedor/replica-parcelas-fornecedor.component';
import { ConfiguracaoNfceComponent } from './cadastros/configuracao-nfce/configuracao-nfce.component';
import { UnidadesEmpresaParametrosNfceService } from '../services/unidades-empresa-parametros-nfce.service';
import { MvaPorEstadosComponent } from './cadastros/mva-por-estados/mva-por-estados.component';
import { CadastroFiscalItemNfceComponent } from './cadastros/cadastro-fiscal-item-nfce/cadastro-fiscal-item-nfce.component';
import { CodigosBeneficiosFiscaisService } from '../services/codigos-beneficios-fiscais.service';
import { CondicaoPagamentoComponent } from './cadastros/condicao-pagamento/condicao-pagamento.component';
import { CondicoesPagamentoService } from '../services/condicoes-pagamento.service';
import { TipoComissaoComponent } from './cadastros/tipo-comissao/tipo-comissao.component';
import { TabelaPrecoDetalheComponent } from '../venda/cadastros/tabelas-preco/tabela-preco-detalhe/tabela-preco-detalhe.component';
import { ExibirVideoComponent } from './diversos/exibir-video/exibir-video.component';
import { ConfiguracoesUsuarioService } from '../services/configuracoes-usuario.service';
import { AliquotaIcmsComponent } from './cadastros/aliquota-icms/aliquota-icms.component';
import { CadastroClienteEnderecoComponent } from './cadastros/cadastro-cliente-endereco/cadastro-cliente-endereco.component';
import { MotivoMovimentacaoEstoqueComponent } from './cadastros/motivo-movimentacao-estoque/motivo-movimentacao-estoque.component';
import { PesagemVolumesComponent } from './cadastros/pesagem-volumes/pesagem-volumes.component';
import { CadastroClassificacaoFiscalComponent } from './cadastros/cadastro-classificacao-fiscal/cadastro-classificacao-fiscal.component';
import { ClassificacoesFiscaisService } from '../services/classificacoes-fiscais.service';
import { UsuariosEmpresaComponent } from './consultas/usuarios-empresa/usuarios-empresa.component';
import { NCMCloudComponent } from './consultas/ncm-cloud/ncm-cloud.component';
import { SelecionarTituloOfxComponent } from './consultas/selecionar-titulo-ofx/selecionar-titulo-ofx.component';
import { BuscaAutomaticaCNPJService } from '../services/busca-automatica-CNPJ.service';
import { RelatorioEstoqueComponent } from './relatorios/estoque/relatorio-estoque/relatorio-estoque.component';
import { RelatorioHistoricoEstoqueComponent } from './relatorios/estoque/relatorio-historico-estoque/relatorio-historico-estoque.component';
import { UtilFunctions } from '../general/utils-service';
import { ImportarNfeComponent } from './cadastros/importar-nfe/importar-nfe.component';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { DimComponentesModule } from '../dim-componentes/dim-componentes.module';
import { AtendimentosComponent } from './relatorios/atendimentos/atendimentos.component';
import { RelatorioAtendimentosService } from '../services/relatorio-atendimentos.service';
import { RotasService } from '../services/rotas.service';
import { RelacaoDeClientesComponent } from './relatorios/clientes/relacao-de-clientes/relacao-de-clientes.component';
import { ConclusaoAdesaoComponent } from './relatorios/conclusao-adesao/conclusao-adesao.component';
import { ComissoesComponent } from './relatorios/comissoes/comissoes/comissoes.component';
import { DestinacaoRecursosComponent } from './relatorios/destinacao-recursos/destinacao-recursos.component';
import { ModalRelatorioRetencoesComponent } from './relatorios/notas-fiscais/modal-relatorio-retencoes/modal-relatorio-retencoes.component';
import { NotasFiscaisComunicacaoComponent } from './relatorios/notas-fiscais/notas-fiscais-comunicacao/notas-fiscais-comunicacao.component';
import { RastreabilidadeLotesMedicamentoComponent } from './relatorios/notas-fiscais/rastreabilidade-lotes-medicamento/rastreabilidade-lotes-medicamento.component';
import { ResumoVendasPorNcmComponent } from './relatorios/notas-fiscais/resumo-vendas-por-ncm/resumo-vendas-por-ncm.component';
import { SaidasPorEstadoComponent } from './relatorios/notas-fiscais/saidas-por-estado/saidas-por-estado.component';
import { VendaProdutosNfceComponent } from './relatorios/notas-fiscais/venda-produtos-nfce/venda-produtos-nfce.component';
import { RelacaoVendasProdutoComponent } from './relatorios/pedidos/relacao-vendas-produto/relacao-vendas-produto.component';
import { SaldoContasComponent } from './relatorios/saldo-contas/saldo-contas/saldo-contas.component';
import { NotasFiscaisService } from '../services/notas-fiscais.service';
import { AtendimentoContabilidadeService } from '../services/atendimento-contabilidade.service';
import { DespesasService } from '../services/despesas.service';
import { ContabilidadesService } from '../services/contabilidades.service';
import { NotasFiscaisComunicacaoService } from '../services/notas-fiscais-comunicacao.service';
import { NfConsumidorService } from '../services/nf-consumidor.service';
import { PedidosService } from '../services/pedidos.service';
import { MovimentacoesService } from '../services/movimentacoes.service';
import { DownloadExportacaoService } from '../services/downloadExportacaoController.service';
import { EnviarEmailComponent } from './diversos/enviar-email/enviar-email.component';
import { ExportarNFEComponent } from './consultas/gerenciamento/exportar-nfe/exportar-nfe.component';
import { ExportarNfceComponent } from './consultas/gerenciamento/exportar-nfce/exportar-nfce.component';
import { VincaularTipoDespesaEventosBancariosComponent } from './cadastros/vincaular-tipo-despesa-eventos-bancarios/vincaular-tipo-despesa-eventos-bancarios.component';
import { LancarEstoqueComponent } from './cadastros/lancar-estoque/lancar-estoque/lancar-estoque.component';
import { RelatorioGerencialComponent } from './relatorios/notas-fiscais/relatorio-gerencial/relatorio-gerencial.component';
import { RelatorioProdutosComponent } from './relatorios/produtos/relatorio-produtos/relatorio-produtos.component';
import { RelatorioContasPagarComponent } from './relatorios/relatorio-contas-pagar/relatorio-contas-pagar/relatorio-contas-pagar.component';
import { GraficosComponent } from './relatorios/dashboard/graficos/graficos.component';
import { AvisoCertificadoComponent } from './diversos/aviso-certificado/aviso-certificado.component';
import { ReplicaHorariosAgendaComponent } from './cadastros/replica-horarios-agenda/replica-horarios-agenda.component';
import { DetalhesNfeComponent } from './consultas/detalhes-nfe/detalhes-nfe.component';
import { BuscaItensRetornoComponent } from './consultas/busca-itens-retorno/busca-itens-retorno.component';
import { ItensManifestoComponent } from './consultas/itens-manifesto/itens-manifesto.component';
import { SerieAlternativaComponent } from './cadastros/serie-alternativa/serie-alternativa.component';
import { NotasFiscaisComponent } from './consultas/notas-fiscais/notas-fiscais.component';
import { TabelasPrecoService } from '../services/tabelas-preco.service';
import { LoteLancamentosContabeisComponent } from './cadastros/lote-lancamentos-contabeis/lote-lancamentos-contabeis.component';
import { LancamentosContabeisService } from '../services/lancamentos-contabeis.service';
import { ListaItensFechamentoComponent } from './diversos/lista-itens-faturacao/lista-itens-fechamento.component';
import { PedidosEntregaComponent } from './relatorios/pedidos-entrega/pedidos-entrega.component';
import { IntermediadoresComponent } from './cadastros/intermediadores/intermediadores.component';
import { EmissoesPorCfopComponent } from './relatorios/notas-fiscais/emissoes-por-cfop/emissoes-por-cfop.component';
import { ProdutosCloneComponent } from './cadastros/produtos-clone/produtos-clone.component';
import { RelatorioProdutosPorOrigemComponent } from './relatorios/produtos/relatorio-produtos-por-origem/relatorio-produtos-por-origem.component';
import { MapaRemessasRetornosComponent } from './relatorios/notas-fiscais/mapa-remessas-retornos/mapa-remessas-retornos.component';
import { EvasaoComponent } from './relatorios/evasao/evasao.component';
import { EditarCfopNotaEntradaComponent } from './cadastros/editar-cfop-nota-entrada/editar-cfop-nota-entrada.component';
import { ListarNotasCorrespondentesComponent } from './consultas/listar-notas-correspondentes/listar-notas-correspondentes.component';
import { CartaCorrecaoComponent } from './consultas/carta-correcao/carta-correcao.component';
import { ComissoesSaldoComponent } from './relatorios/comissoes/comissoes-saldo/comissoes-saldo.component';
import { PoliticaDePrivacidadeComponent } from './diversos/politica-de-privacidade/politica-de-privacidade.component';
import { AlterarDadosFiscaisComponent } from './cadastros/alterar-dados-fiscais/alterar-dados-fiscais.component';
import { NotasFiscaisVendaPorClienteComponent } from './relatorios/notas-fiscais-venda-por-cliente/notas-fiscais-venda-por-cliente.component';
import { NotasFiscaisVendaPorProdutoComponent } from './relatorios/notas-fiscais-venda-por-produto/notas-fiscais-venda-por-produto.component';
import { BuscaItensRemessaComponent } from './consultas/busca-itens-remessa/busca-itens-remessa.component';
import { FormaPagamentoModalComponent } from '../gerenciamento/portal-do-cliente/forma-pagamento-modal/forma-pagamento-modal.component';
import { AvisoFaturasComponent } from './diversos/aviso-faturas/aviso-faturas.component';
import { ParametrosSistemaService } from '../services/parametros-sistema.service';
import { BuscaItensRomaneioComponent } from './consultas/busca-itens-romaneio/busca-itens-romaneio.component';
import { BuscarRomaneioComponent } from './consultas/buscar-romaneio/buscar-romaneio.component';
import { RelatorioRomaneioCargaComponent } from './relatorios/romaneio-carga/relatorio-romaneio-carga/relatorio-romaneio-carga.component';
import { BuscaItensPorContaOrdemComponent } from './consultas/busca-itens-por-conta-ordem/busca-itens-por-conta-ordem.component';
import { FaturamentoDetalhadoComponent } from './consultas/faturamento-detalhado/faturamento-detalhado.component';
import { CollapseComponent } from './consultas/faturamento-detalhado/collapse/collapse.component';
import { SelecionarCfopItensReplicadosComponent } from './cadastros/selecionar-cfop-itens-replicados/selecionar-cfop-itens-replicados.component';
import { WhatsappOtpComponent } from './cadastros/whatsapp-otp/whatsapp-otp.component';
import { ValidacaoNumeroWhatsAppService } from '../services/validacao-numero-whatsApp.service';
import { SelecionarUsuarioAlterarSenhaComponent } from './diversos/selecionar-usuario-alterar-senha/selecionar-usuario-alterar-senha.component';
import { CadastroServicoComponent } from './cadastros/cadastro-servico/cadastro-servico.component';
import { ModalCadastroEnderecoRetiradaComponent } from './cadastros/modal-cadastro-endereco-retirada/modal-cadastro-endereco-retirada.component';
import { ExportarNFSEComponent } from './consultas/gerenciamento/exportar-nfse/exportar-nfse.component';
import { ContratarIntermediadorRecebimento } from './diversos/contratar-intermediador-recebimento/contratar-intermediador-recebimento.component';
import { NgxCurrencyDirective } from 'ngx-currency';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { ModalSegmentosVinculadosComponent } from './diversos/modal-segmentos-vinculados/modal-segmentos-vinculados.component';
import { NgxMaskConfig } from '../config/NgxMaskConfig';
import { ModalProcessoImportacaoComponent } from './cadastros/modal-processo-importacao/modal-processo-importacao.component';
import { VisualizarProcessosDeImportacaoComponent } from './consultas/visualizar-processos-de-importacao/visualizar-processos-de-importacao.component';
import { ModalUnidadesConsumoVinculadosComponent } from './diversos/modal-unidades-consumo-vinculados/modal-unidades-consumo-vinculados.component';
import { ModalCadastroUnidadesConsumoComponent } from './cadastros/modal-cadastro-unidades-consumo/modal-cadastro-unidades-consumo.component';
import { RepresentantesServiceV2 } from '@servicesv2';
import { BuscaItensNotaEntradaComponent } from './consultas/busca-itens-nota-entrada/busca-itens-nota-entrada.component';
import { AtalhosClientesComponent } from './cadastros/atalhos-clientes/atalhos-clientes.component';
import { ConfiguracaoItensNfeComponent } from './cadastros/configuracao-itens-nfe/configuracao-itens-nfe.component';
import { CteImportarNfePropriaComponent } from './diversos/cte-importar-nfe-propria/cte-importar-nfe-propria.component';
import { CteImportarXmlCteComponent } from './diversos/cte-importar-xml-cte/cte-importar-xml-cte.component';
import { CteImportarXmlNfeComponent } from './diversos/cte-importar-xml-nfe/cte-importar-xml-nfe.component';
import { ExportarCTEComponent } from './consultas/gerenciamento/exportar-cte/exportar-cte.component';
import { EnviarCartaCorrecaoComponent } from './diversos/enviar-carta-correcao/enviar-carta-correcao.component';
import { CartaCorrecaoCteComponent } from './consultas/carta-correcao-cte/carta-correcao-cte.component';
import { EditarCfopNotaSaidaComponent } from './cadastros/editar-cfop-nota-saida/editar-cfop-nota-saida.component';

@NgModule({
  declarations: [
    CentroCustoComponent,
    ContaCorrenteComponent,
    GrupoDespesaComponent,
    PortadorComponent,
    PedidoComponent,
    TipoDespesaComponent,
    AlmoxarifadoComponent,
    LinhaProdutoComponent,
    FornecedorComponent,
    CertificadoDigitalComponent,
    EditarUsuarioComponent,
    EstornoContasReceberComponent,
    AcessosUsuarioComponent,
    ReplicaParcelasCobrancaComponent,
    RepresentanteComponent,
    ClienteComponent,
    ReplicaParcelasFornecedorComponent,
    ConfiguracaoNfceComponent,
    MvaPorEstadosComponent,
    CadastroFiscalItemNfceComponent,
    CondicaoPagamentoComponent,
    TipoComissaoComponent,
    TabelaPrecoDetalheComponent,
    CadastroClienteEnderecoComponent,
    ExibirVideoComponent,
    AliquotaIcmsComponent,
    MotivoMovimentacaoEstoqueComponent,
    PesagemVolumesComponent,
    CadastroClassificacaoFiscalComponent,
    UsuariosEmpresaComponent,
    NCMCloudComponent,
    SelecionarTituloOfxComponent,
    RelatorioEstoqueComponent,
    RelatorioHistoricoEstoqueComponent,
    AtendimentosComponent,
    RelacaoDeClientesComponent,
    ComissoesComponent,
    ConclusaoAdesaoComponent,
    DestinacaoRecursosComponent,
    ModalRelatorioRetencoesComponent,
    NotasFiscaisComunicacaoComponent,
    RastreabilidadeLotesMedicamentoComponent,
    ResumoVendasPorNcmComponent,
    SaidasPorEstadoComponent,
    VendaProdutosNfceComponent,
    RelacaoVendasProdutoComponent,
    SaldoContasComponent,
    ImportarNfeComponent,
    ExportarNFEComponent,
    ExportarNfceComponent,
    EnviarEmailComponent,
    SaldoContasComponent,
    VincaularTipoDespesaEventosBancariosComponent,
    LancarEstoqueComponent,
    RelatorioGerencialComponent,
    RelatorioProdutosComponent,
    RelatorioContasPagarComponent,
    GraficosComponent,
    AvisoCertificadoComponent,
    ReplicaHorariosAgendaComponent,
    DetalhesNfeComponent,
    ItensManifestoComponent,
    BuscaItensRetornoComponent,
    SerieAlternativaComponent,
    NotasFiscaisComponent,
    LoteLancamentosContabeisComponent,
    ListaItensFechamentoComponent,
    PedidosEntregaComponent,
    IntermediadoresComponent,
    EmissoesPorCfopComponent,
    ProdutosCloneComponent,
    RelatorioProdutosPorOrigemComponent,
    MapaRemessasRetornosComponent,
    EvasaoComponent,
    EditarCfopNotaEntradaComponent,
    ListarNotasCorrespondentesComponent,
    CartaCorrecaoComponent,
    ComissoesSaldoComponent,
    PoliticaDePrivacidadeComponent,
    AlterarDadosFiscaisComponent,
    NotasFiscaisVendaPorClienteComponent,
    NotasFiscaisVendaPorProdutoComponent,
    BuscaItensRemessaComponent,
    FormaPagamentoModalComponent,
    AvisoFaturasComponent,
    BuscaItensRomaneioComponent,
    BuscarRomaneioComponent,
    RelatorioRomaneioCargaComponent,
    BuscaItensPorContaOrdemComponent,
    FaturamentoDetalhadoComponent,
    CollapseComponent,
    SelecionarCfopItensReplicadosComponent,
    WhatsappOtpComponent,
    SelecionarUsuarioAlterarSenhaComponent,
    CadastroServicoComponent,
    ModalCadastroEnderecoRetiradaComponent,
    ExportarNFSEComponent,
    ContratarIntermediadorRecebimento,
    ModalSegmentosVinculadosComponent,
    ModalProcessoImportacaoComponent,
    VisualizarProcessosDeImportacaoComponent,
    ModalUnidadesConsumoVinculadosComponent,
    ModalCadastroUnidadesConsumoComponent,
    BuscaItensNotaEntradaComponent,
    AtalhosClientesComponent,
    ConfiguracaoItensNfeComponent,
    CteImportarNfePropriaComponent,
    CteImportarXmlCteComponent,
    CteImportarXmlNfeComponent,
    ExportarCTEComponent,
    EnviarCartaCorrecaoComponent,
    CartaCorrecaoCteComponent,
    EditarCfopNotaSaidaComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgScrollbarModule,
    TagInputModule,
    FileUploadModule,
    DimComponentesModule,
    NgxCurrencyDirective,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  providers: [
    MessageService,
    CentrosCustosService,
    ContascorrentesService,
    BancosService,
    GrupoDespesasService,
    PortadorService,
    TipoDespesaService,
    AlmoxarifadosService,
    TabelasPrecoService,
    LancamentosContabeisService,
    LinhasProdutosService,
    FornecedoresService,
    EstadosService,
    CidadesService,
    UnidadesEmpresaService,
    TitulosCobrancaService,
    RepresentantesService,
    ClientesService,
    UnidadesEmpresaParametrosNfceService,
    CodigosBeneficiosFiscaisService,
    DownloadExportacaoService,
    CondicoesPagamentoService,
    ConfiguracoesUsuarioService,
    ClassificacoesFiscaisService,
    BuscaAutomaticaCNPJService,
    UtilFunctions,
    RelatorioAtendimentosService,
    RotasService,
    CidadesService,
    EstadosService,
    ClientesService,
    NotasFiscaisService,
    AtendimentoContabilidadeService,
    DespesasService,
    ContabilidadesService,
    NotasFiscaisComunicacaoService,
    NfConsumidorService,
    PedidosService,
    MovimentacoesService,
    DownloadExportacaoService,
    ParametrosSistemaService,
    ValidacaoNumeroWhatsAppService,
    provideNgxMask(NgxMaskConfig),
    RepresentantesServiceV2,
  ],
  exports: [
    ModalRelatorioRetencoesComponent,
    RelacaoDeClientesComponent,
    ConfiguracaoItensNfeComponent,
  ]
})
export class ModaisModule { }
